/* -------------------- General Site layouts -------------------- **/
*{
	font-family: $font-stack;
	color: $textDark;
	text-decoration: none !important;
	outline: none !important;
	-webkit-font-smoothing: antialiased;
}

/*
font-family: "tenez";
font-family: "questa-grande";
*/

body{
	overflow-y: scroll;
	overflow-x: hidden;
	background-color: #fff;
}

img{
	max-width: 100%;
}


/*** Page ***/
.Page_results,
.InnerPage{
	.banner{
		height: 66vh;
	}
}

.vacancy{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	display: block;
	float: left;
	width: 100%;
}



.CalendarFilter{
	padding: 10px;
}

/* .CalendarFilter{
	span{
		display: inline-block;
		padding: 5px 0px;
		margin: 0px 5px;
		cursor: pointer;
	}
} */
.CalendarEvent #allEvents ul li,
.Calendar #allEvents ul li{
/* 	display: none; */
}
/* .CalendarEvent.All #allEvents ul li,
.Calendar.All #allEvents ul li{
	display: block;
} */
.CalendarFilter span{
	cursor: pointer;
	display: inline-block;
	padding: 5px;
	border-bottom: 2px solid transparent;
	margin-bottom: 10px;
}
.CalendarFilter span.active{
	border-bottom: 2px solid $themeColor !important;
	color: $themeColor !important;
}
.noEvents{
	display: none;
	padding: 20px 10px;
	color: #666;
	@include border-radius(3px);
    background-color: #eee;
}

#allEvents ul{
	max-width: 500px;
	margin: 0px !important;
	padding: 0px !important;
}

#allEvents ul li{
	margin-bottom: 10px;
	background-color: $themeColor;
	float: left;
    width: 100%;
    clear: both;
    @include transition(background 0.35s);
    display: block;
}






.toggleContent{
	display: none;
	font-size: 0.8em;
	*{
		font-size: 1em;
		color: #333 !important;
	}
	.url{
		padding: 10px;
    	color: #fff !important;
    	background-color: $themeColor;
    	@include transition($t1);
    	@include border-radius(3px);
    	/*&:hover{
    		background-color: #5a5962 !important;
    		color: #fff !important;
    	}*/
	}
}


.eventTitle{
	color: #333 !important;
	padding: 10px;
	text-transform: uppercase;
}
.toggleBtn{
	cursor: pointer;
	sup{
	display: none !important;
	}
}

.calendar-widget-table tbody .calendar-header td{
	
	    background-color: $themeColor;
}

.news-teaser{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	display: block;
	float: left;
	width: 100%;

	h3{
  		color: $themeColor;
  		font-size: 1.5em;
	}
	img{
		@include border-radius(50%);
	}
}



.noticesBG{
	position: fixed;
	display: none;
	top: 0px;
	left: 0px;
	z-index: 9973;
	height: 100%;
	width: 100%;
	background-color: rgba(0,0,0, 0.75);
}
.noticesContainer{
	position: fixed;
	z-index: 9999;
	top: 50%;
	border-bottom: 4px solid #03b5f0;
	display: none;
	left: 50%;
	@include transform($center);
	background-color: #fff;
	max-width: 100%;
	width: 500px;
}
.NoticeTitle{
	background-color: #03b5f0;
	color: #fff !important;
	padding: 20px !important;
font-family: "tenez";
    border-bottom: none !important;
    font-size: 1.6em;
}
.readMoreLinkNotices{
    font-size: 0.8em;
    border: 1px solid #03b5f0;
    padding: 3px 5px;
    margin-top: 10px;
    display: inline-block;
}
.fullWidth{
	position: relative;
	clear: both;
	width: 100%;
	float: left;
}
.closeNotices{
	position: absolute;
	top: 23px;
	right: 10px;
	padding-right: 20px;
*{	color: #fff !important;}
	font-size: 1em;
	cursor: pointer;
	z-index: 9999; 

}

#NoticesSliderContainer{
	padding: 0px 20px !important;
}
.noticeTitle{
	color: #03b5f0 !important;
	font-size: 1.5em;
	padding-bottom: 10px;
	font-family: "tenez";
}

.bx-pager-link{
	color: transparent !important;
	display: inline-block;
	width: 10px;
	height: 10px;
	margin-right: 10px;
	@include border-radius(50%);
	background-color: #b5b4b9;
	&.active{
		background-color: #03b5f0 !important;
	}
}
.noticesInner{
	overflow: hidden;
	*{
		color: #333;
	}
	.col-12{
		padding: 20px 0px;
	}

}

.MainMenuClose{
	display: none !important;
}
.bx-pager-item{
	display: inline-block;
}
.mainContent{
	input[type=text],
	input[type=email],
	input[type=password],
	textarea{
	    width: 100%;
	    padding: 10px 20px;
	    background-color: #eee;
	    border: none !important;
	    margin-bottom: 10px;
	    margin-top: 5px;
	    width: 100%;
	    max-width: 100%;
	 
    	font-weight: lighter;
	}

	label{
		font-weight: lighter;
	}

	input[type=submit]{
			padding: 10px 20px !important;
		    display: block;
		    width: 100%;
		    border: none !important;
		    color: #fff !important;
		    font-weight: lighter;
		    margin-top: 10px;
		    @include transition($t1);
		    @include border-radius(3px);
		    max-width: 100%;
			background-color: #1b3080;
			&:hover{
		    	background-color: #5a5962 !important;
		    }
	}
	#ForgotPassword{
		margin-top: 20px;
		a{
			font-weight: lighter;
			color: #1d1e3c;
		}
	}
}
.addToCalendar,
.attachment,
.readMoreLink,
.button{
		padding: 10px 20px !important;
	    display: inline-block;
	    text-align: center;
	    color: #fff;
	    font-size: 0.7em !important;
	    cursor: pointer;
	    *{
	    	font-size: 1em;
	    	color: #fff;

	    @include transition($t1);
	    }
	    border: none !important;
	    color: #fff !important;
	    margin-top: 10px;
	    @include transition($t1);
	    @include border-radius(3px);
	   	background-color: $themeColor;
		&:hover{
	    	background-color: #4b4b4b !important;
	    }
}

/** Inputs  **/
#content{
	input[type=text],
	input[type=email],
	input[type=password],
	textarea{
	    width: 100%;
	    padding: 10px 20px;
	    background-color: #eee;
	    border: none !important;
	    margin-bottom: 10px;
	    margin-top: 5px;
	    width: 100%;
    	font-weight: lighter;
	}

	label{
		font-weight: lighter;
	}

	input[type=submit]{
			padding: 10px 20px !important;
		    display: block;
		    width: 100%;
		    border: none !important;
		    color: #fff !important;
		    font-weight: lighter;
		    margin-top: 10px;
		    @include transition($t1);
		    @include border-radius(3px);
		    background-color: $themeColor;
			max-width: none !important;
		    width: auto !important;
		    display: inline-block;

			&:hover{
	    	    background-color: #71142b !important;
		    }
	}
	#ForgotPassword{
		margin-top: 20px;
		a{
			font-weight: lighter;
			color: #1d1e3c;
		}
	}
}
/** Inputs  **/



.vacancy{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	display: block;
	float: left;
	width: 100%;
}



.heading-2{
	color: $themeColor !important;font-weight: lighter;
	*{color: $themeColor !important;font-size: 1em;font-weight: lighter;}
}
.archives{
	position: relative;
	display: block;
	float: left;
	width: 100%;
	clear: both;
	padding: 0px !important;
	margin: 0px 0px 30px 0px !important;

	.archive{
		position: relative;
		display: block;
		float: left;
		clear: both;
		color: #fff;
		width: 100%;
		margin: 5px 0px !important;
		cursor: pointer;
		@include transition($t1);
		img{
			position: absolute;
		    right: 10px;
		    top: 50%;
		    max-width: 17px;
			@include transform(translateY(-50%));
		}
		a{
			display: block;
			width: 100%;
			clear: both;
			float: left;
			padding: 8px 30px  8px 15px;
			top: 0px;
			left: 0px;
			width: 100%;
			z-index: 1;
			height: 100%;
		}
		*{
		@include transition($t1);
			color: #fff !important;
		}
		
		@include border-radius(3px);

	    background-color: $themeColor !important;

		 &:hover{
	    	    background-color: #454545 !important;
	    		*{
	    			color: #fff !important;
	    		}
		    }
	}
}

.SubDate{
	margin-top: 20px;
    font-size: 1.4em;
    margin-bottom: 10px;
}


.archive{
	*{
		color: #fff !important;
	}
}
/*
|===============================================================
|	Uploads
|===============================================================
*/

.upload{
	position: relative;
	display: block;
	width: 100%;
	max-width: 500px;
	clear: both;
	float: left;
	padding: 10px 45px 12px 30px;
	border: 1px solid $themeColor;
	margin: 5px 0px;
	background-color: transparent;
	@include transition($t1);
	.uploadTitle{
		text-transform: uppercase;
		font-size: 1em;
		font-weight: normal !important;
		color: $themeColor !important;
	    display: inline-block;
	}
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
	.fileIcon{


		position: absolute;
		right: 10px;
		top: 50%;
		@include transform(translateY(-50%));



	    background-color: $themeColor;
	    display: inline-block;
	    float: right;
	    width: 25px;
	    height: 30px;
	    @include transition($t1);
	    span{
	    	@include transition($t1);
	    	color: #fff !important;
		    background-color: #000;
		    height: 13px;
		    width: 100%;
		    padding-top: 2px;
		    font-size: 7px;
		    text-transform: uppercase;
		    display: block;
		    text-align: center;
		    margin-top: 8px;
	    }
	}

	&:hover{
		background-color: #e2e0df !important;
		color: #1b3080 !important;
		.uploadTitle{
			/*color: #000 !important;*/
		}
		/*.fileIcon{
	    	background-color: #000 !important;
			span{
			    color: #000 !important;
				background-color: #009e7f;
		   }
		}*/
	}
}


.upload{
	/*padding: 10px;
	border-bottom: 2px solid #eee;*/
	*{
		vertical-align: middle;
		font-weight: lighter !important;
	}
}





.galleryImage{
	display: block;
	width: 33.33%;
	position: relative;
	padding-bottom: 33.33%;

	.galleryImageInner{
		position: absolute;
		overflow: hidden;
		width: 95%;
		height: 95%;
		left: 50%;
		top: 50%;
		background-color: #2b2b2b;
		@include transform(translate(-50%,-50%));
		.gallerImageBg{
			@include transition($t1);
			position: absolute;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-positon: center;
			left: 0px;
			top: 0px;
			opacity: 1;
		}
		.gallerImageText{
			opacity: 0;
			text-align: center;
			width: 95%;
			@include transition($t1);
			position: absolute;
			top: 50%;
			left: 50%;
			color: #fff !important;
			@include transform(translate(-50%,-50%) scale(0.5));
		}
	}
	&:hover{
		.galleryImageInner{
			.gallerImageBg{
				opacity: 0.2 !important;
			}
			.gallerImageText{
				@include transform(translate(-50%,-50%) scale(1));
				opacity: 1 !important;
			} 
		}
	}
	a{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		z-index: 2;
	}
}

/*
|===============================================================
|	Gallery 3
|===============================================================
*/

#bx_slider {
	height: 500px;
	li {
		height: 100%;
		text-align: center;
		img {
			display:inline-block;
			height: 100%;
		}
	}
}
.innerSlideGal{
	padding-bottom: 100%;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
}

.GalNext,
.GalPrev{
	cursor: pointer;
	position: absolute;
	z-index: 200;
	top: 50%;
	margin-top: 8px;
	@include transform(translateY(-50%));
	i{
		color: #fff !important;
	}
}
.GalNext{
	left: 0px;
	padding: 20px;
	background-color: rgba(0,0,0,0.9);
}
.GalPrev{
	right: 0px;
	padding: 20px;
	background-color: rgba(0,0,0,0.9);
}

.bx_pages_frame {
	position: relative;
	#bx_pages {
		margin-top: 20px;
		li {
			background-position: center center;
			cursor: pointer;
			height: 200px;
			    opacity: 0.5;
			    @include transition(opacity 0.35s);
			    &:hover{
			   	 opacity: 1 !important;
			    }
		}
	}
	.controls {
		i {
			position: absolute;
			top: 50%;
			color: $mainColor2;
			font-size: 24px;
			margin-top: -12px;
			cursor: pointer;
			@include transition(all 0.5s);
			&:hover {
				opacity: 0.5;
			}
		}
		i.prev {
			left: 0;
		}
		i.next {
			right: 0;
		}
	}
}

/*
|===============================================================
|	Masonry
|===============================================================
*/

.grid {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 700px;
	a {
		font-size: 0;
		line-height: 0;
	}
}

.grid-item, .grid-sizer { 
	position: relative;
	width: 25%; 
	float: left;
	padding: 2px;
	cursor: pointer;
	@include transition(all 0.5s);
	img {
		width: 100%;
		height: auto;
	}
}

.grid-item.hoverState {
	opacity: 0.7;
}

.grid-item--width2 { width: 400px; }


.galleryImage{
	width: 33.33%;
    float: left;
	position: relative;
	padding-bottom: 33.33%;

	.galleryImageInner{
		position: absolute;
		overflow: hidden;
		width: 95%;
		height: 95%;
		left: 50%;
		top: 50%;
		background-color: #2b2b2b;
		@include transform(translate(-50%,-50%));
		.gallerImageBg{
			@include transition($t1);
			position: absolute;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-positon: center;
			left: 0px;
			top: 0px;
			opacity: 1;
		}
		.gallerImageText{
			opacity: 0;
			text-align: center;
			width: 95%;
			@include transition($t1);
			position: absolute;
			top: 50%;
			left: 50%;
			color: #fff !important;
			@include transform(translate(-50%,-50%) scale(0.5));
		}
	}
	&:hover{
		.galleryImageInner{
			.gallerImageBg{
				opacity: 0.2 !important;
			}
			.gallerImageText{
				@include transform(translate(-50%,-50%) scale(1));
				opacity: 1 !important;
			} 
		}
	}
	a{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		z-index: 2;
	}
}
#CustomLoginForm_LoginForm{
	max-width: 400px;
	width: 100%;
}

.staffProfile {
	.staffProfilePic {
		width:200px;
		height:200px;
		background-size: cover;
		background-position:center center;
		border-radius:20px;
	}
}
.HorizontalStack {
	.staffProfile {
		width:auto;
		margin:0 30px 30px 0;
		.profilePic {
			margin:0 !important;
		}
	}
}

/*
|===============================================================
|	Staff Page Templates
|===============================================================
*/

.StaffPage {
	.staffProfile {
		display:inline-block;
		width:100%;
		margin:30px;
		.profilePic {
			margin:0 30px 0 0;
			float:left;
			display:inline-block;
		}
		.bio {
			min-width:50%;

			p {
				display:block;
			}
		}
	}
}

.StaffPageStack {
	.staffProfile {
		text-align:center;
		margin-bottom:60px;
		.bio {
			p {
				text-align:justify;
				width:100%;
				max-width:800px;
				margin:0 auto 20px auto;
			}
		}
	}
}

.StaffPageNoPic {
	.staffProfile {
		text-align:center;
		margin-bottom:60px;
		.bio {
			p {
				text-align:justify;
				width:100%;
				max-width:800px;
				margin:0 auto 20px auto;
			}
		}
	}
}

.bio{
	h4{
		font-weight: normal;
    	font-size: 1.2em;
	}

	h3{
		text-transform: uppercase;
		font-weight: lighter;
	}
}

 .HorizontalStack .staffProfile {
    width: auto;
    margin: 0 30px 30px 0;
}

.VerticalStack{
	    margin: 0px ;
	  
	    .staffProfile {
	    	margin: 0px ;
			padding: 30px 0px;
			border-bottom: 2px solid #eee;
		}
}
.NameDepOnly{
	    margin: 0px ;
	  
	    .staffProfile {
	    	margin: 0px ;
			padding: 30px 0px;
			border-bottom: 2px solid #eee;
		}
}
.HorizontalStack{
	.profilePic{
		padding-bottom: 20px;
	}
}

.profilePic img{
	display: inline-block;
	padding-bottom: 20px;
}

.VerticalStack{
	.profilePic img{
		display: inline-block;
		padding-bottom: 0px;
	}
}


#SearchResults{ 
	padding: 0px;
	margin: 0px;
	display: block;
	float: left;
	width: 100%;
	font-size: 0.8em;
	li{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	margin: 0px;
	display: block;
	float: left;
	width: 100%;
		list-style: none !important;
	}
	.readMoreLink{
		font-size: 1em !important;
	}
}


.NewsletterItem{
	display: block;
	width: 20%;
	float: left;
	padding: 10px;
	.NewsletterInner{
		display: block;
		position: relative;
		width: 100%;
		@include border-radius(3px);
		@include transition($t1);
		border: 5px solid #fff;
		@include box-shadow(0px 0px 10px 0px #9c9c9c);
		overflow: hidden;
		.newsletterThumb{
			float: left;
			clear: both;
			background-color: #eee;
			width: 100%;
			background-image: url(../images/newsletter.png);
			background-size: 60%;
    		background-repeat: no-repeat;
			background-position: center;
			padding-bottom: 110%;
		}
		a{
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
		}
	}
	&:hover{
		.NewsletterInner{
			border: 5px solid $themeColor !important;
		}
	}
	.newsletterTitle{
		padding: 10px;
		font-size: 0.8em;
		float: left;
	    width: 100%;
	    text-align: center;
	    clear: both;
	}
}

.Newsletter{
	width: 20%;
    padding-bottom:20%;
    position: relative;
	min-width: 200px;
    min-height: 200px;
    display: inline-block;
    float: none;
}
.NewsLetterInner{
	top: 50%;
	left: 50%;
	width: 90%;
	height: 90%;
	border-radius: 3px;
	overflow: hidden;
	position: absolute; 
	border: 2px solid #d2d2d2;
    background-color: #f9f9f9;
	transition: all 0.35s;
	-o-transition: all 0.35s;
	-ms-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-webkit-transition: all 0.35s;
	transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-o-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
}



.NewsLetterInner .imgContainer{
	display: inline-block;
	position: absolute;
	left: 50%;
	top: 40%;
	transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
}
.Newsletter a{
	position: absolute; 
	width: 100%;
	height: 100%;
	z-index: 200;
	left: 0px;
    top: 0px;
}
.Newsletter:hover .NewsLetterInner{
	border: 2px solid #d2d2d2;
    background-color: #f9f9f9;
	box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-o-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-ms-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
}

.Newsletter .downloadInfo{
	position: absolute;
	transform: translateX(-50%);
	-o-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	left: 50%;
	color: #777;
	width: 98%;
	text-align: center;
	font-size: 1em;
	bottom: 10%;
	transition: all 0.35s;
	-o-transition: all 0.35s;
	-ms-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-webkit-transition: all 0.35s;
}

.Newsletter:hover .downloadInfo{
	
    color: #e42c25 !important;
}

.NewsletterTitle{
	position: relative;
	padding-bottom: 10px;
	margin-bottom: 30px;
	margin-top: 50px;
	text-align: center;
	font-size: 1.5em;
	color: #303c68 !important;
	*{
		font-weight: lighter;
		color: #303c68 !important;
	}
}

.InnerPage{
    .calPrev,
    .calNext {
        top: 37px;
    }
    .calendar-widget-table,
    .outerCalendarContainer{
        width: 100%;
    }
    .eventContainer{
        display: none !important;
    }
    .calHeader{
        .col-7,
        .col-5{
            width: 100%;
            text-align: center;
        }
        .col-5{
            display: none !important;
        }
    }
}
.group1{
	@include transition($t1);
	opacity: 0;
}

#gmap_canvas{
	min-height: 400px;
}


.SearchContainer{
    display: none;
    position: fixed;
    top: -4px;
    background-color: #dbb95a;
    padding: 7px;
    right: 0px;
    z-index: 9999;
	@include transform(translateY(100%))

	#Search{
		display: block;
		float: left;
	}

    .middleColumn,
    button,
    #SearchForm_SearchForm_Search_Holder{
    	float: left;
    	display: block;
    }

    button{
	    background-color: #dbb95a;
	    border: none !important;
	    color: #fff !important;
	    padding: 5px 10px;
	    @include border-radius(0px !important);
 

	    i{
	    	color: #fff !important;
	    }
	    float: left;
    	display: block;
    }
    input[type=submit]{
    	@include border-radius(0px !important);
        display: block;
	    border: none !important;
	    font-size: 1em;
	    text-transform: uppercase;
	    padding: 6px;
	    color: #fff !important;
	    font-style: italic;
	    background-color: #dbb95a;
    }
    input[type=text]{
	    border: 0px;
	    padding: 5px 10px 7px 10px;
	    color: #5f5e6d;
	    float: left;
    	@include border-radius(3px !important);
    	display: block;
    }
} 


/** General Site layouts **/
















.CenterItems{
	display: flex;
	align-items: center;
	justify-content: center;
}

.flex{
	display: flex;
}

.slink{
	cursor: pointer;
}


.translate{
	cursor: pointer;
}
.TranslateBG{
	display: none;
	position: fixed;
	top: 0px;
	z-index: 9999;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(41,37,89,0.76);
}
#google_translate_element{
	display: none;
	position: fixed; 
	z-index: 9999;
	top: 50%;
	left: 50%;
	@include transform($center);
}

#gmap_canvas{
	min-height: 500px;
}

.SearchContainer{
    display: none;
    position: fixed;
    top: -9px;
    background-color: #3d3a61;
    padding: 7px;
    left: 20px;
    z-index: 9999;
	@include transform(translateY(100%))

	#Search{
		display: block;
		float: left;
	}

    .middleColumn, 
    button,
    #SearchForm_SearchForm_Search_Holder{
    	float: left;
    	display: block;
    }

    button{
	    background-color: #56b4b9;
	    border: none !important;
	    color: #fff !important;
	    padding: 5px 10px;
   -webkit-appearance: none !important;
   -moz-appearance:    none !important;
   appearance:         none !important;


	    i{
	    	color: #fff !important;
	    }
	    float: left;
    	display: block;
    }
    input[type=submit]{
        display: block;
	    border: none !important;
	    font-size: 1em;
	    text-transform: uppercase;
	    padding: 5px;
	    color: #fff !important;
	    font-style: italic;
	    background-color: #56b4b9;
	
   -webkit-appearance: none !important;
   -moz-appearance:    none !important;
   appearance:         none !important;

    }
    input[type=text]{
	    border: 0px;
	    padding: 5px 10px;
	    color: #5f5e6d; 
	    float: left;
    	display: block;
   -webkit-appearance: none !important;
   -moz-appearance:    none !important;
   appearance:         none !important;
    }
}

/*** Scroll Bar ***/
::-webkit-scrollbar{
	width:8px;
	height:7px;
}
::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment{
	display:block;
	height:0; 
	background-color:transparent;
}
::-webkit-scrollbar-track-piece{
	background-color: #1c2023;
	@include border-radius(0px);
}
::-webkit-scrollbar-thumb{
	background-color: $themeColor;
	@include border-radius(0px); 
}
::-webkit-scrollbar-thumb:vertical{
}
::-webkit-scrollbar-thumb:horizontal{
}
::-webkit-scrollbar-thumb:hover{
	background-color: $themeColor;
}


.TopLevel::-webkit-scrollbar{
	width:4px;
	height:7px;
}
.TopLevel::-webkit-scrollbar-button:start:decrement,
.TopLevel::-webkit-scrollbar-button:end:increment{
	display:block;
	height:0; 
	background-color:transparent;
}
.TopLevel::-webkit-scrollbar-track-piece{
	background-color: #2b2b2b;
	@include border-radius(0px);
}
.TopLevel::-webkit-scrollbar-thumb{
	background-color: #1f647a;
	@include border-radius(0px); 
}
.TopLevel::-webkit-scrollbar-thumb:vertical{
}
.TopLevel::-webkit-scrollbar-thumb:horizontal{
}
.TopLevel::-webkit-scrollbar-thumb:hover{
	background-color: #1f647a;
}

.db{
	display: block;
}

fieldset{
	border: none;
}

/*** Search Form ***/


/*** HomePage ***/
.banner{
	position: relative;
	overflow: hidden;
	float: left;
	width: 100%;
	height: 100vh;
	@include background-cover(url("../images/img5.jpg"));

	.darken{
		@include stretchCover(rgba(43,43,43, 0.5), absolute, 1)
	}

	.bg{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		@include background-cover(url("../images/bg.jpg"));
	}
}

.bannerBg{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	@include background-cover(url("../images/bg.jpg"));
}


#google_translate_element{
    border: 30px solid #312d5e;
}



/*** Page ***/
.Page{
	.banner{
		height: 50vh;
		.bg {
			height: 105%;
		}
	}
}
/*** Footer ***/
footer{
	padding: 30px 0px 0px 0px;
	*{
		font-family: "tenez";
	}
}


/*** Menu ***/
#Menu{
	float: left;
    z-index: 9999;
    width: 100%;
    text-align: center;
	display: inline-block;
	text-align: right;
	*{
		color: #000;
		font-family: "tenez";
	}
	/*
	.MenuButton{
		display: inline-block;
		position: absolute;
		z-index: 2;
		cursor: pointer;
		background-color: #73a8d9;
	    right: 0px;
	    padding: 8px 12px 7px 12px;
	    cursor: pointer;
	    opacity: 1;
	    top: 0px;
	    text-transform: uppercase;
	}
	*/
	.MenuContainer{
    	text-align: center;
		position: relative;
		.LevelOne{
			display: inline-block; 
			text-align: center;
			a{    
				@include transition($t1);
				padding: 4px 20px 7px 20px;
    			font-size: 1em;
				line-height: 3em;
				display: inline-block;
    			line-height: 1.1em;
			}
		}
	}
	.topMenuLink{
		text-transform: uppercase;
				@include transition($t1);
		&:hover{
			color: #03b5f0 !important; 
		}
	}


	.SubMenu{
		@include box-shadow(-2px 13px 40px 0px rgba(47, 47, 47, 0.33));
		width: 100%;
		height: 0px;
		font-size: 1em;
		overflow: hidden;
		display: block;
		z-index: -9999;
    	left: 50%;
    	text-align: left;
		position: absolute;
	    background-color: #f6f6f7;
	    border-radius: 0px;
	    margin-top: 0px; 
	   /* padding: 3px 10px 6px 10px;*/
	    opacity: 0;
	 /*   transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -o-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -ms-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -moz-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -webkit-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;*/
	    @include transform(translateY(20px) translateX(-50%) scale(0.98));
	    border-bottom: 4px solid #03b5f0;

	    a{
		    display: block;
		    width: 100%;
		    padding: 10px 0px !important;
		    /*font-size: 0.9em;*/
		    line-height: 1.2em !important;
		    width: 100%;
			background-color: transparent;
	    	@include transition($t1);
			&:hover{
    			background-color: rgba(255,255,255,0.2) !important;
			}
		}
	}
	/*.menuItem-1,
	.menuItem-2{
*/		.SubMenu{
			left: 0%;
			@include transform(translateY(20px) translateX(0%) scale(0.98));
		}
	/*}*/
/*	.menuItem-Last{
		.SubMenu{
			left: auto; 
			right: 0%;
			@include transform(translateY(20px) translateX(0%) scale(0.98));
		}
	}*/
	.active .SubMenu{
		opacity: 1 !important;
	 	@include transform(translateY(0px) translateX(0%) scale(1) !important);
	}
	/*.menuItem-1.active .SubMenu,
	.menuItem-2.active .SubMenu{
		opacity: 1 !important;
	 	@include transform(translateY(0px) translateX(0%) scale(1) !important);
	}
	.menuItem-Last.active .SubMenu{
			opacity: 1 !important;
			left: auto;
			right: 0%;
			@include transform(translateY(0px) translateX(0%) scale(1) !important);	
	}*/

	/*.SubMenu:before {
	    content: '';
	    display: block;
	    position: absolute;
	    width: 100%;
	    height: 17px;
	    left: 0px;
	    top: -14px;
	    z-index: 2;
	}
	.SubMenu:after {
	    content: '';
	    display: block;
	    position: absolute;
	    border: 10px solid transparent;
	    top: -20px;
	    left: 50%;
	    margin-left: -10px;
	    border-bottom: 10px solid #2f3b67;
	}*/
}

.flexslider .flex-direction-nav {
    position: absolute;
    bottom: 16%;
    left: 50%;
    height: 30px;  
    z-index: 800;
    width: calc(100% - 80px);
    @include transform($center);
}
.flexslider .slides li {
    position: relative;
    height: 100vh;
}
.quoteBox {
    position: absolute;
    top: 50%;
    left: 150px;
    @include transform(translate(0%, -50%));
    z-index: 2;
    color: #fff;
    font-size: 2em;
    display: block;
    opacity: 1;
    line-height: 1.2em;
    text-transform: uppercase;
    *{
    	color: #fff !important;
    }
}

.slideBG{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-position: center;
	background-size: cover;
	background-image: url(../images/bg.jpg);
}


.policyBar{
	padding: 10px;
	font-size: 0.7em;
	background-color: #2b2b2b;
	color: #fff;
	*{
	color: #fff;
	}
}

.contentPage table{
	border-collapse: collapse;
    border: 1px solid #c7c7c7;
    }
.contentPage table th,
.contentPage table td{
padding: 10px 5px !important;
	border-spacing: 0;
    border-collapse: collapse;
    border: 1px solid #c7c7c7;
}

.contentPage table th{
	background-color: #efececd1;
}

.pageList{
	a{
		display: block;
		margin-bottom: 5px;
		background-color: #eee;
		padding: 10px 20px;
		color: #475f79 !important;
		@include transition($t1);
		&:hover{
			background-color: #475f79 !important;
			color: #fff !important;
		} 
	}
}

.CalendarFilter{
	padding: 10px;
}

/* .CalendarFilter{
	span{
		display: inline-block;
		padding: 5px 0px;
		margin: 0px 5px;
		cursor: pointer;
	}
} */
.CalendarEvent #allEvents ul li,
.Calendar #allEvents ul li{
/* 	display: none; */
}
/* .CalendarEvent.All #allEvents ul li,
.Calendar.All #allEvents ul li{
	display: block;
} */
.CalendarFilter span{
	cursor: pointer;
	display: inline-block;
	padding: 5px;
	border-bottom: 2px solid transparent;
	margin-bottom: 10px;
}
.CalendarFilter span.active{
	border-bottom: 2px solid $themeColor !important;
	color: $themeColor !important;
}
.noEvents{
	display: none;
	padding: 20px 10px;
	color: #666;
	@include border-radius(3px);
    background-color: #eee;
}

#allEvents ul{
	max-width: 500px;
	margin: 0px !important;
	padding: 0px !important;
}

#allEvents ul li{
	margin-bottom: 10px;
	background-color: $themeColor;
	float: left;
    width: 100%;
    clear: both;
    @include transition(background 0.35s);
    display: block;
}






.toggleContent{
	display: none;
	font-size: 0.8em;
	*{
		font-size: 1em;
		color: #333 !important;
	}
	.url{
		padding: 10px;
    	color: #fff !important;
    	background-color: $themeColor;
    	@include transition($t1);
    	@include border-radius(3px);
    	/*&:hover{
    		background-color: #5a5962 !important;
    		color: #fff !important;
    	}*/
	}
}



.calendar-widget-table tbody .calendar-header td{
	
	    background-color: $themeColor;
}

/*
|===============================================================
|	Calendar Page Template Widget
|===============================================================
*/
.calendar-widget-table {
	width: 100%;
	background-color: #fff;
	margin:0 0 2em;
	*{
		text-align: center;
	}
	thead {
		tr {
			th {
				border: none;
    			padding: 15px 20px;
				background-color: #475f79;
				    font-size: 1em;
				.show-month, .next, .prev {
					display:inline-block;
					color: #FFFFFF;
					font-weight: bold;
					font-weight: lighter;
    				font-size: 1.3em;
					i {
						color: #fff;
					}
					.inner {
						color: #FFFFFF;
						width: auto;
					}
				}
				.show-month {
					padding: 0 20px;
				}
			}
		}
	}
	.calendar-header {
		td.calendar-header-day, td {
			background: transparent !important;
			color: #475f79;
			border:none !important;
		}
	}
	tbody{
		.calendar-day {
			border: 1px solid #ececec;
			color: #a9afb9;
			@include transition($t1);
		}
		tr {
			&:nth-child(even){ 
				background-color:transparent; 
			}
			td {
			position: relative;
				padding: 10px 0px !important;
				z-index: 1;
				text-align: center !important;
				.calEventPopup {
					position: absolute;
					z-index: 80 !important;
					padding:20px;
					border:2px solid $LinkColor;
					background-color: #fff;
					    width: 180px;
					    text-align: center;
					    display:none;
					.dayTitle {
						color:$LinkColor;
						margin-bottom:20px;
						font-weight:500;
					}
					.eventLine {
						color:$LinkColor;
					}

				}
				z-index: 1;
				&.hasEvent:hover {
				z-index: 9990;
					.calEventPopup {
						display: block;
						z-index: 90 !important;
					}
				}
				&:nth-child(1) .calEventPopup { margin-left:0; }
				&:nth-child(2) .calEventPopup { margin-left:-20px; }
				&:nth-child(3) .calEventPopup { margin-left:-40px; }
				&:nth-child(4) .calEventPopup { margin-left:50%; @include transform(translateX(-50%)); }
				&:nth-child(5) .calEventPopup { margin-right:-40px; }
				&:nth-child(6) .calEventPopup { margin-right:-20px; }
				&:nth-child(7) .calEventPopup { margin-right:0px; }
			}
		}
		.hasEvent {
			background-repeat: no-repeat;
			background-position: 5px 5px;
			background-size: 4px 4px;
		}
		.today {
			color: #676b71;
		}
		
		.selected{color: #fff !important;}
		.out-of-month {
			background-color:transparent !important;
			color: #efefef;
		}
	} 
	.calendar-day:hover, .selected {
		background-color: rgba(71, 95, 121, 0.05) !important;
		
					color: $themeColor;
	}
}

.calendarWidget{
    padding: 10px;
}

/*
|===============================================================
|	Calendar Filter
|===============================================================
*/

.CalendarFilter{
    padding: 10px;
}

/* .CalendarFilter{
    span{
        display: inline-block;
        padding: 5px 0px;
        margin: 0px 5px;
        cursor: pointer;
    }
} */
.CalendarEvent #allEvents ul li,
.Calendar #allEvents ul li{
/*     display: none; */
}
/* .CalendarEvent.All #allEvents ul li,
.Calendar.All #allEvents ul li{
    display: block;
} */
.CalendarFilter span{
    cursor: pointer;
    display: inline-block;
    padding: 5px;
    color: #333 !important;
    border-bottom: 2px solid transparent;
    margin-bottom: 10px;
}
.CalendarFilter span.active{
    border-bottom: 2px solid $themeColor !important;
    color: $themeColor !important;
}
.noEvents{
    max-width: 500px;
    display: none;
    padding: 20px 10px;
    color: #666 !important;
    @include border-radius(3px);
    background-color: #eee;
}

#allEvents ul{
    max-width: 500px;
    margin: 0px !important;
    padding: 0px !important;
}
#allEvents ul li,
#HomepageEvents ul li{
    @include border-radius(5px);
    margin-bottom: 10px;
    background-color: #475f79 ;
    float: left;
    width: 100%;
    clear: both;
    margin-left: 0;
	span.suffix {
		font-size: 12px;
		vertical-align: top;
		margin-left: 2px;
		line-height: 6px;
	}
    @include transition(background 0.35s);
    display: block;
    
    .toggleBtn{
        cursor: pointer;
        padding: 10px;
        padding-left: 20px;
        color: #fff;
        *{
			color: #fff;
        }
		.arrow {
			position: absolute;
			top: 15px;
			right: 15px;
			color: #fff;

		    -webkit-transition-duration: 0.8s;
			-moz-transition-duration: 0.8s;
			-o-transition-duration: 0.8s;
			transition-duration: 0.8s;

			-webkit-transition-property: -webkit-transform;
			-moz-transition-property: -moz-transform;
			-o-transition-property: -o-transform;
			 transition-property: transform;
		}
		&.active {
			.arrow {
				@include transform(rotate(180deg));
			}
		}
    }
}

.toggleContent{
	display: none;
	font-size: 0.8em;
	*{
		font-size: 1em;
		color: #fff !important;
	}
	.url{
		padding: 10px;
    	color: #333 !important;
    	background-color: $themeColor;
    	@include transition($t1);
    	&:hover{
    		background-color: #4d4d4d !important;
    		color: #fff !important;
    	}
	}
}
.toggleBtn{
	cursor: pointer;
}

.calendar-widget-table thead tr th {
    padding: 10px 20px 10px 20px;
}

.calendar-widget-table thead tr th .show-month{
	font-size: 1.2em !important;
}

.calendar-widget-table thead tr th{
	    background-color: #03b5f0 !important;
}
#allEvents ul li, #HomepageEvents ul li{
	    background-color: #03b5f0 !important;
	    *{
	    color: #fff !important;
	    }
}
#HomepageEvents ul li,
#allEvents ul li{
	position: relative;
}
#allEvents ul li .toggleBtn, #HomepageEvents ul li .toggleBtn {
 	padding: 0px !important;
    float: left;
    width: 100%;
}

.calday{
	position: absolute;
    top: 10px;
    left: 19px;
}
.eventTitle{
	padding-left: 110px;
	padding-right: 35px;
	width: 100% !important;
}

.error {
    display: inline-block;
    border: 1px solid #da8486;
    margin: 10px 0px 20px 0px;
    padding: 5px 20px;
    font-size: 1em;
    color: #da8486;
    background-color: #ffdfdf;
}


/* -------------------- General Site layouts -------------------- **/




/* -------------------- Site Build -------------------- **/
.leftBorder{
	position: fixed;
	width: 30px;
	height:  100%;
	display: block;
	left: 0px;
	top: 0px;
	background: #fff;
	z-index: 9977;
}
.rightBorder{
	position: fixed;
	width: 30px;
	height:  100%;
	display: block;
	right: 0px;
	top: 0px;
	background: #fff;
	z-index: 9977;
}
.topBorder{
	position: fixed;
	width: 100%;
	height: 30px;
	top: 0px;
	left: 0px;
	display: block;
	background: #fff;
	z-index: 9977;
}
.bottomLeftBorder{
	position: fixed;
	width: calc(50% - 30px);
	height: 30px;
	display: block;
	left: 0px;
	bottom: 0px;
	@include transition($t1);
	background: #fff;
	z-index: 9977;
	&:after{
		position: absolute;
	    content: '';
	    display: block;
	    border: 15px solid transparent;
	    border-bottom: 15px solid #fff;
	    right: -29px;
	    border-left: 15px solid #fff;
	}
}
.bottomRightBorder{
	position: fixed;
	right: 0px;
	bottom: 0px;
	@include transition($t1);
	width: calc(50% - 30px);
	height: 30px;
	display: block;
	background: #fff;
	z-index: 9977;
	&:before{
		position: absolute;
	    content: '';
	    display: block;
	    border: 15px solid transparent;
	    border-bottom: 15px solid #fff;
	    left: -29px;
	    border-right: 15px solid #fff;
	}
}

header{
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	z-index: 9999;
}

.homebtn{
	position: absolute;
	top: 50px;
	left: 50px;
	z-index: 99;
	img{
		width: 350px;
	}
}

.searchToggle{
	position: fixed;
	top: -1px;
	right: -1px; 
	width: 70px;
	cursor: pointer;
	z-index: 9999;
	@include transition(all 100ms);
	@include transform(scale(1));
	transform-origin: 100% 0%;
	-o-transform-origin: 100% 0%;
	-ms-transform-origin: 100% 0%;
	-moz-transform-origin: 100% 0%;
	-webkit-transform-origin: 100% 0%;
	&:hover{
		@include transform(scale(1.1));
	}
}

.BannerInfo{
	position: absolute;
	bottom: 70px;
	left: 0px;
	z-index: 7;
	width: 100%;
}
.bannerText{
	font-size: 3em;
	font-style: italic;
	padding-bottom: 30px;
font-family: "questa-grande";
		color: #fff;
}
.bannerLinks{
	background-color: rgba(13, 91, 146, 0.58);
	padding: 0px 30px;
}
.bannerLink{
     vertical-align: middle;
	background-color: transparent;
	@include transition(all 200ms); 
	padding: 25px 20px;
	display: inline-block;
	position: relative;
	width: 20%;
	font-family: "tenez";
	font-size: 1.5em;
	color: #fff;
	*{
		color: #fff;
	}
	&:after{
		content: '';
		position: absolute;
		display: block;
		height: 30px;
		width: 2px;
		right: -1px;
		top: 50%;
		background-color: #fff;
		right: 0px;
		@include transform(translateY(-50%));
	}
	&:hover{
		background-color: #03b5f0;
	}
	&:last-of-type:after{
		display: none;
	}
}

.bannerContact{
	font-size: 1em;
	padding: 3px 30px;
	z-index: 10;
	position: fixed;
	bottom: 0px;
	right: 0px;
	z-index: 9989;
	font-family: "tenez";
	*{
		font-family: "tenez";
		display: inline-block;
		vertical-align: middle;
	}
	img{
		height: 15px;
		margin-left: 15px;
	}
}


.QuicklinksContainer{
	position: fixed;
	@include transition(all 1s);
	top: 50%;
	@include transform(translateY(-50%));
	left: 0px;
	z-index: 9978;    
	font-size: 1.3em;
	opacity: 1;
	background-color: #03b5f0;
	padding-left: 30px;
	height: 48px;
	&:after{
	    content: '';
	    position: absolute;
	    display: block;
	    border: 24px solid transparent;
	    border-left: 20px solid #03b5f0;
	    right: -44px;
	    top: 0px;
	}
	&:before{
		content: 'Quick links';
		text-transform: uppercase;
		color: #fff;
		font-family: "tenez";
		position: absolute;
		display: block;
		right: -160px;
		top: 50%;
		opacity: 1;
		@include transform(translateY(-50%));
		@include transition(all 350ms);
	}
	&:hover:before{
		opacity: 0;
	}
}
.QuicklinksInner{
	width: 0px;
	white-space: nowrap;
	overflow: hidden;
	a{
		padding: 7px 20px;
		display: inline-block;
		float: left;
		text-align: center;
		white-space: nowrap;
		color: #fff;
		font-family: "tenez";
		@include transition(all 150ms);
		position: relative;
		&:after{
			position: absolute;
			content: '';
			display: block;
			height: 70%;
			width: 2px;
			right: -1px;
			top: 50%;
			background-color: #fff;
			@include transform(translateY(-50%));
		}
		&:hover{
			background-color: #2881c1;
		}
	}
	a:last-of-type:after{
		display: none;
	}
}
.HiddenQuicklinks{
	display: block;
	float: left;
}
#Home2{
	padding: 100px 40px 180px 40px;
	.inner{
		width: 780px;
	}
}

.WelcomeTitleOne{
	text-align: center;
	text-transform: uppercase;
    font-size: 2.4em;
	color: #595960;
	font-family: "tenez";
}
.WelcomeTitleTwo{
	text-align: center;
	font-style: italic;
	font-family: "questa-grande";
    font-size: 2.5em;
	color: #03b5f0;
	margin-bottom: 50px;
}
.WelcomeRight{
	padding: 5px;
	font-weight: lighter;
	*{font-weight: lighter;}
}
.WelcomeLeft{
	padding: 5px;
	.readMore{
	    font-size: 0.9em;
    	font-weight: bold;
	}
}
.WelcomeTitleThree{
	font-size: 1.8em;
	color: #03b5f0;
	font-family: "tenez";
	padding-bottom: 30px;
}
.readMore{
	font-family: "tenez";
	display: inline-block;
	padding: 5px 20px;
	text-transform: uppercase;
	font-size: 0.8em;
	color: #03b5f0;
	border: 1px solid #cecdd0; 
	@include transition($t1);
	&:hover{
		background: #03b5f0; 
		border: 1px solid #03b5f0; 
		color: #fff;
	}
}
.WelcomeRight{

}

.overlay1{
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -20px;
	@include transform($center);
	opacity: 0.1;
	width: 130px;
}

.hexagon {
  position: relative;
  width: 300px; 
  height: 173.21px;
  background-color: #edecee;
  margin: 86.60px 0;
}

.hexagon:before,
.hexagon:after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  border-left: 150px solid transparent;
  border-right: 150px solid transparent;
}

.hexagon:before {
  bottom: 100%;
  border-bottom: 86.60px solid #edecee;
}

.hexagon:after {
  top: 100%;
  width: 0;
  border-top: 86.60px solid #edecee;
}

.hexagon1{
	@include transform(scale(0.7) translateX(50%));
	position: absolute;
    right: -44px;
	z-index: 3;
    top: 60px;
}

.maskHex{
    position: absolute;
    top: 303px;
    right: 0px;
    z-index: 0;
    width: 228px;
    height: 270px;
    background-size: cover;
    background-position: center;
	background-image: url(../images/bg.jpg);
}
.maskHexInner{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-size: 100% 100%;
	background-image: url(../images/mask.png);
}

.HexContainer{
    position: absolute;
    width: 345px;
    height: 336px;
    bottom: -120px;
    left: 50px;
}

.hexagon2{
 	background-color: #272224;
	@include transform(scale(0.5));
	position: absolute;
	left: -60px;
	top: -77px;
	@include border-radius(5px);
}

.hexagon2:before {
  	border-bottom: 86.60px solid #272224;
    margin-bottom: -0.6%;
}

.hexagon2:after {
  	border-top: 86.60px solid #272224;
    margin-top: -0.6%;
}


.hexagon3:before {
  	border-bottom: 86.60px solid #03b5f0;
    margin-bottom: -0.6%;
}

.hexagon3:after {
  	border-top: 86.60px solid #03b5f0;
  	margin-top: -0.6%;
}

.hexagon4:before {
  	border-bottom: 86.60px solid #92929f;
    margin-bottom: -0.6%;
}

.hexagon4:after {
  	border-top: 86.60px solid #92929f;
    margin-top: -0.6%;
}


.hexagon3{
 	background-color: #03b5f0;
	@include transform(scale(0.5));
	position: absolute;
	left: 102px;
	top: -77px;
	@include border-radius(5px);
}
.hexagon4{
	@include transform(scale(0.5));
	position: absolute;
 	background-color: #92929f;
	top: 62px;
    left: 21px;
	@include border-radius(5px);
}

.scrollNav{
	.QuicklinksContainer{
		left: -120vw;
		opacity: 0;
		@include transform(translateY(-50%));
	}

	.bottomLeftBorder,
	.bottomRightBorder{
		width: 100%;
	}
}
.Page_results,
.InnerPage{
	.bottomLeftBorder,
	.bottomRightBorder{
		width: 100%;
	}
}
#Home2{
	z-index: 2;	
	min-height: 600px;
}
#Home3{
	z-index: 1;	
	padding: 200px 40px 350px 40px;
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	-webkit-background-attachment: fixed;
	background-image: url(../images/bg.jpg);

	.darken{
		@include stretchCover(rgba(43,43,43, 0.8), absolute, 1)
	}
	a{
		text-transform: uppercase;
	}
	.inner{
		z-index: 90;
	}
	.readMore{
		border: 2px solid #13baf1;
		color: #fff;
		&:hover{
			color: #fff;
		}
	}
}

.innerHex{
	font-size: 2.7em;
	z-index: 9;
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;    
font-family: "tenez";
	width: 85%;
	@include transform($center);
	*{
font-family: "tenez";
		color: #fff;
	}
}
.hexPerc{
	font-size: 2em;
	padding-bottom: 10px;
}
.hexRText{
	font-size: 0.8em;
}

.EventDate{
	font-family: "tenez";
	font-size: 1.1em;
	*{
		font-family: "tenez";
		display: inline-block;
		vertical-align: middle;
		color: #fff;
		margin-right: 10px;
	}
	img{
		width: 15px;
	}
}
.calIcon{
	width: 90%;
	max-width: 60px;
}
.EventTitle{
	color: #fff;
    font-size: 2.4em;
	font-family: "tenez";
	padding-bottom: 15px;
}
.readMore{

}
.EventSectionInner{
	border-bottom: 1px solid #fff;
	padding-bottom: 20px;
	padding-top: 20px;
}

.EventTitleOne{
    font-size: 2.4em;
	text-transform: uppercase;
	color: #fff;
	margin-top: 20px;
	font-family: "tenez";
}
.EventTitleTwo{
    font-size: 2.4em;
    font-style: italic;
	color: #03b5f0;
font-family: "tenez";
	margin-bottom: 15px;
}

.SmallBoxLeft{
	height: 230px;
	background-color: #edecee;
	padding: 20px;
	z-index: 2;
	&:after{
		content: '';
		display: block;
		position: absolute;
		top: 50%;
		@include transform(translateY(-50%));
		border: 30px solid transparent;
		border-left-color: #edecee;
		right: -60px;
	}
}
.SmallBoxRight{
	z-index: 1;
	height: 230px;
	background-size: cover;
	background-position: center;
	background-image: url(../images/bg.jpg);
}
#Home4{
	z-index: 90;
	padding: 0px 35px;
	.inner{
		margin-top: -150px;
	}
}

.SmallBoxLeftTitle{
	color: #03b5f0;
    font-size: 2em;
	font-family: "tenez";
	&:after{
		content: '';
		display: block;
		height: 2px;
		width: 40px;
		position: absolute;
		bottom: -15px;
		left: 0px;
		background-color: #a0a0a4;
	}
}
.LargeBoxTitle{
	color: #fff;
    font-size: 2em;
	font-family: "tenez";
	padding-bottom: 15px;
	margin-bottom: 15px;
	&:after{
		content: '';
		display: block;
		height: 2px;
		width: 40px;
		position: absolute;
		bottom: 0px;
		left: 0px;
		background-color: #fff;
	}
}
.layerOneInner{
	position: absolute;
	top: 30%;
	left: 40px;
	width: 60%;
}

.SmallBoxDate{
	position: absolute;
	left: 0px;
	bottom: 20px;
	font-size: 1.2em;
	font-family: "tenez";
	*{
	font-family: "tenez";
		display: inline-block;
		vertical-align: middle;
	}
	img{
		height: 30px;
		margin-right: 10px;
	}
}

.layerColor,
.SmallBoxRightInner{
	position: absolute;
	top: 0px;
	left: 0px;
	height: 100%;
	width: 100%;
	background-color: #186694;
	opacity: 0;
	@include transition($t1);
}
.layerColor{
	opacity: 0.7;
}
.SmallBoxRightInnerButtons{
	position: absolute;
	top: 50%;
	left: 50%;
	width: 60%;
	opacity: 0;
	@include transition($t1);
	@include transform($center);
	a{
		width: 100%;
		color: #fff;
		text-align: center;
		border: 1px solid #fff;
	}
	a:first-of-type{
		margin-bottom: 15px;
	}
}

.LargeBoxInnerButtons{
	position: absolute;
	bottom: 20px;
	left: 35px;
	width: 100%;
	opacity: 0;
	@include transition($t1);
	a{
		display: inline-block;
		margin: 5px;
		color: #fff;
		text-align: center;
		border: 1px solid #fff;
	}
	a:first-of-type{
		margin-bottom: 15px;
	}
}

.LargeBox{
	.LargeBoxInner{
	height: 500px;
	background-size: cover;
	background-position: center;
	background-image: url(../images/bg.jpg);
	}
	padding: 5px;

	.SmallBoxDate{
		bottom: 100px;
		color: #fff;
font-family: "tenez";
		*{color: #fff;
font-family: "tenez";}
	}
	&:hover{
		.layerColor{
			background-color: #2b2b2b !important;
		}
		.LargeBoxInnerButtons{
			opacity: 1;
		}
	}
}
.SmallBox{
	padding: 5px;
	&:hover{
		.SmallBoxRightInner{
			opacity: 0.7;
		}
		.SmallBoxRightInnerButtons{
			opacity: 1;
		}
	}
}

.layerOne{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
}

.LargeBoxHidden{
	color: #fff;
	overflow: hidden;
}

#Home5{
	padding: 100px 0px;
	.LeftTweets{
		padding-left: 14%;
		min-height: 500px;
	}

	.readMore{
		padding: 8px 20px;
		border: 2px solid #13baf1;
		color: #2b2b2b;
		text-align: center !important;
		&:hover{
			color: #fff;
		}
	}
	.RightNews{

	}
	.TweetsTitle{
	    width: 18%;
		position: absolute;
		left: 30px;
		bottom: 12px;
		a{
			width: 100%;
		}
		.TweetsTitleOne{
    		font-size: 2.4em;
			text-transform: uppercase;
			color: #595960;
			font-family: "tenez";
		}
		.TweetsTitleTwo{
    		font-size: 2.4em;
			font-style: italic;
			font-family: "questa-grande";
			color: #13baf1;
			margin-bottom: 25px;
		}
	}
	.maskHex{
	    position: absolute;
	    top: 0px;
	    left: 0px;
	    z-index: 0;
	    width: 184px;
	    z-index: 5;
	    height: 207px;
	    background-size: cover;
	    background-position: center;
		background-image: url(../images/bg.jpg);
	}
	.maskHexInner{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		background-size: 100% 100%;
		background-image: url(../images/mask2.png);
	}
	.TweetSliderContainer{
		border: 1px solid;
	}
	.TweetSlideInnerContent{
	    padding-top: 155px;
	    padding-left: 92px;
	}
	.TweetSlideInner{
		background-color: #13baf1;
		color: #fff;
		*{color: #fff;}
		padding: 28px;
		min-height: 331px;
		.TweetDate{
			font-size: 0.8em;
			padding-bottom: 30px;
		}
	}
	.TweetText{
		font-size: 1.3em;
		overflow:hidden;
text-overflow:ellipsis;
	}
}

.controls{
	position: absolute;
	z-index: 998;
	top: 50px;
	right: 0px; 
}
.prev{
	background-position: center;
	background-repeat: no-repeat;
	display: inline-block;
	width: 40px;
	height: 40px;
    background-size: 12px;
	border: 2px solid #13baf1;
	@include transition($t1);
	margin: 5px;
	background-image: url(../images/prev.png);
	@include transform(scale(1));
	@include transition(all 100ms);
	&:hover{
		@include transform(scale(1.1));
	}
	position: relative;
	a{
		position: absolute;
		left: 0px;
		top: 0px;
		width: 100%;
		height: 100%;
	}
}
.next{
	background-position: center;
	background-repeat: no-repeat;
	display: inline-block;
	width: 40px;
	height: 40px;
    background-size: 12px;
	border: 2px solid #13baf1;
	@include transition($t1);
	margin: 5px;
	background-image: url(../images/next.png);
	@include transform(scale(1));
	@include transition(all 100ms);
	&:hover{
		@include transform(scale(1.1));
	}
	position: relative;
	a{
		position: absolute;
		left: 0px;
		top: 0px;
		width: 100%;
		height: 100%;
	}
}

.newsControls{
	position: relative;
	top: auto;
	right: auto;
	float: right;
	margin-top: 20px;
	.controls{
		position: absolute;
		top: 50%;
		right: 0px;
		@include transform(translateY(-50%));
	}
}

.NewsTitleOne{
	text-transform: uppercase;
    font-size: 2.4em;
	margin-top: 20px;
	font-family: "tenez";
}
.NewsTitleTwo{
	font-style: italic;
	font-family: "questa-grande";
    font-size: 2.4em;
	color: #13baf1;
	margin-bottom: 57px;
}

.Home6TitleOne{
	text-transform: uppercase;
	font-size: 2em;
	    font-size: 2.4em;
	font-family: "tenez";
	margin-top: 20px;
}
.Home6TitleTwo{
	font-style: italic;
	font-size: 2em;
	    font-size: 2.4em;
font-family: "questa-grande";
	color: #13baf1;
	margin-bottom: 57px;
}

.RightNews{
	padding: 0px 40px 0px 90px;
}

.NewsSlideInner{
	overflow: hidden;
	padding: 20px;
	background-color: #eae9eb;
	@include transform(scale(1));
	@include transition($t1);

	font-family: "tenez";
	&:hover{
		@include transform(scale(0.9));
		background-color: #13baf1;
		span{
			color: #fff;
		}
	}
	span{
		@include transition($t1);
		font-size: 1.6em;
		font-family: "tenez";
	}
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		display: block;
	}
}
.NewsOverlay{
	position: absolute;
	right: -1%;
	height: 110%;
 	top: -5%;
 	opacity: 0.2;
}
#Home6{
	background-color: #eeedef;
	padding: 100px 20px;
	overflow: hidden;
	min-height: 700px;
	
	
}
.innerHabits{
		padding-left: 461px;
	    padding-right: 320px;
	    position: relative;
	    z-index: 90;
	}
.imageOverlay{
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    z-index: 1;
    background-color: #186694;
    opacity: 0.6;
}

.bgImg{
		background-image: url(../images/bg.jpg);
		background-size: cover;
		background-position: center;
		position: absolute;
		top: 0px;
		left: 0px;
		width: 300px;
		overflow: hidden;
		height: 100%;
		&:before{
	    	content: '';
		    position: absolute;
			top: -95px;
			right: -44px;
		    display: block;
		    z-index: 4;
		    border: 182px solid transparent;
		    border-right-color: #eeedef;
		    @include transform(rotate(-12deg));
		    border-top-color: #eeedef;
		}
		&:after{
	    	content: '';
		    position: absolute;
			bottom: -95px;
			right: -44px;
		    display: block;
		    border: 182px solid transparent;
		    border-right-color: #eeedef;
		    @include transform(rotate(12deg));
		    border-bottom-color: #eeedef;
		    z-index: 4;
		}
	}

.habbit{
	cursor: pointer;
	display: inline-block;
	position: relative;
	width: 200px;
	height: 200px;
	transform-style: preserve-3d;
	.front{
		position: absolute;
		height: 100%;
		width: 100%;
		font-family: "questa-grande";
		@include transform(rotateY(0deg));
		@include transition($t1);
		backface-visibility: hidden;
		-webkit-backface-visibility: hidden;
		.hexagon{
			position: absolute;
		    left: 50%;
		    top: 50%;
		    margin-top: 0px;
		    background-color: #fff;
		    @include transform(translate(-50%,-50%) scale(0.6));
		   	&:before{
				border-bottom-color: #fff;
			}
			&:after{
				border-top-color: #fff;
			}
		}
		.contentSection{
			font-size: 1.2em;
			font-family: "questa-grande";

			*{font-family: "questa-grande";}
			position: absolute;
			top: 50%;
			left: 50%;
			font-style: italic;
font-family: "questa-grande";
			width: 80%;
			text-align: center;
			@include transform($center);
			img{
				width: 40px;
				margin-bottom: 10px;
			}
		}
	}
	.back{
		position: absolute;
		height: 100%;
		width: 100%;
		@include transform(rotateY(180deg));
		@include transition($t1);
		backface-visibility: hidden;
		-webkit-backface-visibility: hidden;
		.hexagon{
			position: absolute;
		    left: 50%;
		    top: 50%;
		    margin-top: 0px;
		    @include transform(translate(-50%,-50%) scale(0.6));  
			background-color: #13baf1;
			&:before{
				border-bottom-color: #13baf1;
			}
			&:after{
				border-top-color: #13baf1;
				margin-top: -2px;
			}
		}
		.contentSection{
			position: absolute;
			top: 50%;
			left: 50%;
			width: 80%;
			text-align: center;
			color: #fff;
			@include transform($center);
			img{
				width: 40px;
				margin-bottom: 10px;
			}
		}
	}
	&:hover{
		.front{
			@include transform(rotateY(-180deg));
		}
		.back{
			@include transform(rotateY(0deg));
		}
	}
}
.habitsBottom{
	padding-top: 40px;
	.col-10{
		font-size: 1.3em;
	}
	a.readMore{
		border: 2px solid #13baf1;
		color: #2b2b2b;
		&:hover{
			color: #fff;
		}
	}
}

footer{
	background-color: #2881c1;
	padding: 40px 40px 60px 40px;
	font-sizE: 1.4em;
	.inner{
	 	width: 1700px;
	}
	*{
		color: #fff;
	}
	.footerLink{
		padding: 5px 10px;
		font-size: 0.7em;
		color: #fff;
		text-transform: uppercase;
	}
	.contactTitle{
		text-transform: uppercase;
	}
}
.footerLabel{
	display: inline-block; 
	padding-right: 10px;
}

.footerLogo{
	img{
		width: 60%;
	}
	display: inline-block;
	margin-bottom: 50px;
}

.fse{
	position: absolute;
	bottom: 0px;
	left: 0px;
	font-size: 0.7em;
}

.logoFooter{
	position: absolute;    
	bottom: 7px;
	right: 0px;
}
#up{
	position: absolute;
    bottom: 30px;
    z-index: 999;
    left: 50%;
    margin-left: -36px;
    display: block;
    border: 33px solid transparent;
    border-bottom: 20px solid #fff;
}

.MenuTitle{
	text-transform: uppercase;
	font-size: 1.4em;
	color: #288dd7 !important;
}
.MenuContent{
	font-size: 0.8em;
}

.MenuLeft{
	padding-left: 250px;
	padding-right: 30px;
}

.LevelTwo{
	float: left;
	display: block;
	width: 50%;
	padding: 0px 10px;
	a{
		border-bottom: 1px solid #03b5f0;
		@include transition($t1);
		&:hover{
			color: #03b5f0 !important;
			*{color: #03b5f0 !important;}
			border-bottom: 1px solid #03b5f0;
		}
	}
}

.SubMenu{
	.bgImg{
		width: 220px;
	}
	.bgImg:before {
    	top: -162px;
    	@include transform(rotate(-32deg));
	}
	.bgImg:after {
    	bottom: -162px;
    	@include transform(rotate(32deg));
	}

	.bgImg:after,
	.bgImg:before {
    	border-right-color: #f6f6f7;
	}
}

.sideBarContainer{
	background-color: #f4f4f5;
	font-size: 1em;
	*{font-size: 1em;}
}

#breadcrumbs{
	padding: 20px;
	background-color: #03b5f0;
    float: left;
    width: 100%;
    clear: both;
	.inner{
	    float: left;
	    width: 100%;
	    max-width: 100%;
	    clear: both;
	    padding: 0px 5%;
	}
		font-size: 1em;
		.here{
		font-weight: bold;
		}
	*{
		color: #fff;
		font-size: 1em;
	}
}

.innerContent{
	padding: 40px 10%;
    float: left;
    width: 100%;
}
#sidebar {
	padding-left: 21%;
    padding-right: 19%;
}

#sidebar .sidebar-heading a {
	color: #288dd5 !important;
    text-transform: uppercase;
    font-size: 1.5em;
    margin-bottom: 30px;
}

.InnerPage{
	.QuicklinksContainer{
		bottom: auto;
		top: 0px;
	}
	.bannerLinks{
		z-index: 90;
		padding: 0px 30px;
	}
}
.InnerPageImageInner{
	height: 70vh;
}
.InnerPageImage{
	height: 70vh;
	background-size: cover;
	background-position: center;
	position: static;
	background-attachment: fixed;
	background-image: url(../images/bg.jpg);
	.darken{
		@include stretchCover(rgba(43,43,43, 0.8), absolute, 1)
	}

	.CenterText{
		font-size: 3em;
		position: absolute;
		font-style: italic;
		font-family: "questa-grande";
		z-index: 90;
		color: #fff;
		text-align: center;
		width: calc(100% - 100px);
		top: 50%;
		left: 50%;
		@include transform($center);
	}
}

.InnerPageTitleOne{
	text-transform: uppercase;
	font-size: 2.2em;
	font-family: "tenez";
}
.InnerPageTitleTwo{
font-family: "questa-grande";
	font-style: italic;
	font-size: 2.2em;
	color: #03b5f0;
	padding-bottom: 50px;
}
.InnerPageTitleThree{
	color: #03b5f0;
	font-size: 1.6em;    
	font-family: "tenez";
	margin-bottom: 35px;
}
.ContentSectionTwo{
	padding: 40px 0px;
	margin: 40px 0px;
	border-bottom: 1px solid #03b5f0;
	border-top: 1px solid #03b5f0;
}
.RecommendedList{
	padding-left: 21%;
    padding-right: 19%;
    font-size: 1.1em;
    margin-top: 40px;
        font-family: "tenez";
    a{
		padding: 10px 0px;
		    font-family: "tenez";
    	@include transition($t1);
    	&:hover{color: #03b5f0;}
    }
}
.RecommendedListTitle{
	text-transform: uppercase;
	padding: 10px 0px;
	    font-family: "tenez";
	border-bottom: 1px solid #03b5f0;
}

.SideLinks{ 
	a{
		overflow: hidden;
		color: #fff;
		font-size: 1.5em;
		padding-left: 21%;
    	padding-right: 19%;
    	padding-top: 20px;
		font-family: "tenez";
    	padding-bottom: 20px;
    	*{
			color: #fff;
			font-family: "tenez";
    		display: inline-block;
    	}
    	background-color: #03b5f0;
	}
	.color-1{background-color: #03b5f0;}
	.color-2{background-color: #4a4948;}
	.color-0{background-color: #2881c1;}
}
.PageBoxOuter{
	padding: 5px;
}
.PageBox{
	padding-bottom: 100%;
	background-image: url(../images/bg.jpg);
	background-size: cover;
	background-position: center;
	a.stretch{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
	.BgOverlay{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		background-color: rgba(47,47,47,0.7);
		@include transition($t1);
	}
	&:hover .BgOverlay{
		background-color: rgba(37, 108, 161, 0.77);
	}
	.BoxOverlayText{
		position: absolute;
		width: 80%;
		height: 80%;
		@include transform($center);
		top: 50%;
		left: 50%;
	}
	.hiddenContent{
		overflow: hidden;
		color: #fff;
		*{color: #fff;}
		a{
			border-color: #fff;
			margin-top: 15px;
			font-size: 0.8em;
			&:hover{
				border-color: #389FEC;
			}
		}
	}
	.BoxOverlayTextTitle{
	 	color: #fff;
	 	font-size: 1.7em;
	 	padding: 20px 0px;
      	font-family: "tenez";
	 	margin-bottom: 20px;
	 	&:after{
	 		content: '';
	 		display: block;
	 		width: 40px;
	 		height: 2px;
	 		position: absolute;
	 		bottom: 0px;
	 		left: 0px;
	 		background-color: #fff;
	 	}
	}
}

/* -------- Effects -------- */
#Home2{
	.inner{
		opacity: 0;
		@include transition(all 1s 1000ms);
		@include transform(scale(0.9));
	}
	.hexagon2{
		opacity: 0;
		@include transform(scale(0.3));
	}
	.hexagon3{
		opacity: 0;
		@include transform(scale(0.3));
	}
	.hexagon4{
		opacity: 0;
		@include transform(scale(0.3));
	}
	.group5{
		opacity: 0;
	}
}
#Home2.Active{
	.group5{
		opacity: 1;
	}
	.inner{
		opacity: 1;
		@include transform(scale(1));
	}
	.hexagon2{
		opacity: 1;
		@include transform(scale(0.5));
	}
	.hexagon3{
		opacity: 1;
		@include transform(scale(0.5));
	}
	.hexagon4{
		opacity: 1;
		@include transform(scale(0.5));
	}
}

#Home3{
	.inner{
		opacity: 0;
		@include transition(all 1s 500ms);
		@include transform(scale(0.8)); 
	}
}
#Home3.Active{
	.inner{
		opacity: 1;
		@include transform(scale(1));
	}
}

#Home4{
	.SmallBox,
	.LargeBox{
		opacity: 0;
		@include transform(scale(0.7));
	}
}
#Home4.Active{
	.SmallBox,
	.LargeBox{
		opacity: 1;
		@include transform(scale(1));
	}
}

#Home5{
	.inner{
		@include transition(all 1s 1800ms);
		opacity: 0;
	}
}
#Home5.Active{
	.inner{
		opacity: 1;
	}
}
#Home6{
	.group1{
		@include transform(scale(0.8)); 
		opacity: 0;
	}
}
#Home6.Active{
	.group1{
		opacity: 1;
		@include transform(scale(1)); 
	}

}
.loadingSection{
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: #fff;
    z-index: 9999999;
}
.lds-infinity{
	position: absolute;
	top: 50%;
	left: 50%;
	@include transform($center);
}

.group2{
	opacity: 0;
}
.PageLoaded{
	.group2{
		opacity: 1;
	}
}
.Loading{
	overflow: hidden !important;
}


#SideNav{
	position: fixed;
	top: 50%;
	right: 0px;
	@include transform(translateY(-50%));
	z-index: 9998;
	.slink{
		color: #000;
	}
	*{
		font-family: "tenez";
	}
	.dot{
	    display: block;
	    text-align: center;
	    float: right;
	    height: 30px;
	    font-size: 1em;
	    padding-top: 4px;
	    width: 30px !important;
	    -webkit-transition: all 0.35s;
	    -moz-transition: all 0.35s;
	    -ms-transition: all 0.35s;
	    transition: all 0.35s;
	    background-color: #fff;
	    vertical-align: middle;
	}
	.sectionTitle{
		padding: 4px 6px;
		@include transition($t1);
		opacity: 1;
		font-size: 1em;
		vertical-align: middle;
		float: right;
		display:block;
		color: #fff !important;
		height: 30px;
    	width: 100px;
		background: #88b5dc;
	}
	.hidden{
    	width: 0px;
		overflow: hidden;
		float: right;
		display:block;
		@include transition($t1);
	}
	.slink{
		cursor: pointer;
		&:hover{
			.hidden{
				width: 100px !important;
			}
			.sectionTitle{
				color: #fff !important;
			}
			.dot{
				background: #e3e2e5;
				color: $themeColor !important;
			}
		}
	}
	.slink.active{
		/*.hidden{
			width: 100px !important;
		}*/
		.sectionTitle{
			color: #fff !important;
		}
		.dot{
			background: #e3e2e5;
			color: $themeColor !important;
		}
	}
}

.searchBg{
	position: fixed;
	top: 0px;
	display: none;
	left: 0px;
	width: 100%;
	height: 100%;
    z-index: 99999999;
	background-color: rgba(31, 116, 144, 0.95);
}
.searchContainer{
	display: none;
	position: fixed;
	top: 50%;    
	width: 272px;
	left: 50%;
    z-index: 99999999;
	*{
		float: left;
	}
	@include transform($center);
	input[type=text]{
		background-color: #1a6279;
	    border: none !important;
	    padding: 20px;
	    color: #fff;
	}
	#SearchForm_SearchForm_action_results{
		background-color: #ffffff;
	    border: none;
	    padding: 20px;
	    color: #27758f;
	    font-style: italic;
	    text-transform: uppercase;
	    display: block;
	    cursor: pointer;
	    @include transition($t1);
	    &:hover{
	    	background-color: #efefef;
	    }
	}
}

#__toolbox-opener {
	-index: 99999;
    top: 100px;
    right: 50px;
}



.FileUpload{
	overflow: hidden;
	cursor: pointer;
	@include transition($t1);
	display: inline-block;
	width: 100%;
	max-width: 500px;
	padding: 10px 20px;
	margin-bottom :10px;
	text-transform: uppercase;
	border: 2px solid $themeColor;
	color: #2b2b2b !important;
	padding-left: 50px;
	position: relative;
	    text-transform: uppercase;

    font-family: "tenez";
	*{
		color: #2b2b2b;
	}
	&:before{
		@include transition($t1);
		content: '\f019';
		display: block;
		position: absolute;
		font-family: FontAwesome;
		top: 50%;
		opacity: 0;
		left: 10px;
			color: #2b2b2b;
		*{
			color: #2b2b2b;
		}
		@include transform(translateY(-100%));
	}
	&:after{
		@include transition($t1);
		display: block;
		content: '\f0f6';
		position: absolute;
		font-family: FontAwesome;
		top: 50%;
		left: 10px;
		opacity: 1;
			color: #2b2b2b;
		*{
			color: #2b2b2b;
		}
		@include transform(translateY(-50%));
	}
	&:hover{

			color: #2b2b2b;
		border: 2px solid $themeColor;
		&:before{
			color: #2b2b2b;
			opacity: 1;
			@include transform(translateY(-50%));
		}
		&:after{
			color: #2b2b2b;
			opacity: 0;
			@include transform(translateY(50%));
		}
	}

	.fileIcon{
		display: none;
	}
}

.fancybox-overlay-fixed {
    position: fixed;
    z-index: 99999 !important;
}
.fancybox-opened {
    z-index: 999999 !important;
}


.calendar-widget-table thead tr th{
	background-color: transparent !important;
	    text-align: right;
	    color: #2b2b2b !important;
}

.calendar-widget-table thead tr th .show-month {
    position: absolute;
    left: 0px;
	    color: #2b2b2b !important;
    top: 27px;
    text-transform: uppercase;
    font-size: 1.1em !important;
    font-family: "tenez";
}


.GalNext .prev,
.GalPrev .next{
	background-image: none !important;
	border: none !important;
	height: auto !important;
	width: auto !important; 
}
.NoticesOverlay{
	position: absolute;
	bottom: 0px;
	right: -5%;
	height: 80%;
	opacity: 0.1;
}
.cookieWarning{
	width: 100% !important;
}
.contactDetails{
	font-size: 0.7em;
	overflow:hidden;
	/* text-overflow:ellipsis;*/
}

.emailFooter{
	    word-break: break-all;
}
.sideBarContainer{
	overflow: hidden;
}

.SidebarOverlay{
	position: absolute;
	bottom: 0px;
	right: -5%;
	width: 40%;
	opacity: 0.05;
}
.italic{
	font-style: italic;
	font-family: "questa-grande";
	color: #03b5f0;
	font-size: 1.2em
}

footer{
	.divider{
		display: inline-block;
		padding: 0px 5px;
	}
}

#Home7{
	padding: 200px 40px;
}

.HousePointsImage{
	position: absolute;
	right: 0px;
	height: 100%;
	width: 40%;
	opacity: 0.9;
	top: 0px;
	background-size: cover;
	background-position: center;
	background-image: url(../images/bg.jpg);
}

.HousePointsImageOverlay{
	position: absolute;
	right: 0px;
	height: 100%;
	width: 40%;
	top: 0px;
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,1+23,0+100 */
	background: -moz-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 23%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 23%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 23%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */
}

.HousePoint{
	margin-bottom: 30px;
	display: inline-block;
	position: relative;
	opacity: 0;
	width: 200px;
	@include transform(scale(0.8));
	height: 200px;
	transform-style: preserve-3d;
	*{
		color: #fff;
	}
	.points{
			font-style: normal !important;
		font-size: 4em;
		padding-bottom: 10px;
	}
	.front{
		position: absolute;
		height: 100%;
		width: 100%;
		font-family: "questa-grande";
		@include transform(rotateY(0deg));
		@include transition($t1);
		backface-visibility: hidden;
		-webkit-backface-visibility: hidden;
		.hexagon{
			position: absolute;
		    left: 50%;
		    top: 50%;
		    margin-top: 0px;
		    @include border-radius(3px);
		    background-color: #75c8f0;
		    @include transform(translate(-50%,-50%) scale(0.6));
		   	&:before{
				border-bottom-color: #75c8f0;	
					top: -85px;
			}
			&:after{
				border-top-color: #75c8f0;
				margin-top: -2px;
			}
		}
		.contentSection{
			font-size: 1.2em;
			font-family: "questa-grande";

			*{font-family: "questa-grande";}
			position: absolute;
			top: 50%;
			left: 50%;
			font-style: italic;
			font-family: "questa-grande";
			width: 80%;
			text-align: center;
			@include transform($center);
			img{
				width: 40px;
				margin-bottom: 10px;
			}
		}
	}
	.back{
		position: absolute;
		height: 100%;
		width: 100%;
		@include transform(rotateY(180deg));
		@include transition($t1);
		backface-visibility: hidden;
		-webkit-backface-visibility: hidden;
		.hexagon{
			position: absolute;
		    @include border-radius(3px);
		    left: 50%;
		    top: 50%;
		    margin-top: 0px;
		    @include transform(translate(-50%,-50%) scale(0.6));  
			background-color: #13baf1;
			&:before{
				border-bottom-color: #13baf1;
				top: -85px;
			}
			&:after{
				border-top-color: #13baf1;
				margin-top: -2px;
			}
		}
		.contentSection{
			position: absolute;
			top: 50%;
			left: 50%;
			width: 80%;
			text-align: center;
			color: #fff;
			@include transform($center);
			img{
				width: 40px;
				margin-bottom: 10px;
			}
		}
	}
/*	&:hover{
		.front{
			@include transform(rotateY(-180deg));
		}
		.back{
			@include transform(rotateY(0deg));
		}
	}*/
}



.HousePoint-2{
	.front{
		.hexagon{
			    background-color: #ffd24f;
		&:before{
					border-bottom-color: #ffd24f;
				}
				&:after{
					border-top-color: #ffd24f;

				}
			}
	}
}


.HousePoint-3{
	.front{
		.hexagon{
			    background-color: #bc2029;
		&:before{
					border-bottom-color: #bc2029;
				}
				&:after{
					border-top-color: #bc2029;

				}
			}
	}
}

.HousePoint-4{
	.front{
		.hexagon{
			    background-color: #806bac;
		&:before{
					border-bottom-color: #806bac;
				}
				&:after{
					border-top-color: #806bac;

				}
			}
	}
}
.HousePoint-0{
	.front{
		.hexagon{
			    background-color: #1d9b4e;
		&:before{
					border-bottom-color: #1d9b4e;
				}
				&:after{
					border-top-color: #1d9b4e;

				}
			}
	}
}


#Home7{
	.group11{
		@include transform(scale(0.8)); 
		opacity: 0;
	}
}

#Home7.Active{
	.group11{
		opacity: 1;
		@include transform(scale(1)); 
	}
}

.pageList{
	a{
		display: block;
		margin-bottom: 5px;
		background-color: #eee;
		padding: 10px 20px;
		color: #475f79 !important;
		@include transition($t1);
		&:hover{
			background-color: #475f79 !important;
			color: #fff !important;
		} 
	}
}

.habitLink{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%; 
	height: 100%;
}

.footImg{
	max-width: 150px;
}

#ValuesContainer{
	padding: 100px 5%;
	background-color: #f4f4f5;
	.habbit{
		margin-bottom: 30px;
	}

	.TitlePartOne{
    text-transform: uppercase;
    font-size: 2.2em;
    font-family: "tenez";
	}
	.TitlePartTwo{
   	 	font-size: 3em;
    	font-family: "tenez";
    	color: #2881c1;
    	font-style: italic;
	}
}

.tal{
	text-align: left !important;
}

.pageDib{
	display: inline-block;
	position: relative;
	max-width: 100%;
    width: 614px;
}
/* -------------------- Site Build -------------------- **/

