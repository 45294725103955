
/*** Desktop Screens ***/
@media all and (max-width: 1000px) {
   #sidebar{
      display: none;
   }
   .sideBarContainer{
      padding: 0px 30px;
      width: 100%;
      clear: both;
   }
   .MainContentArea{
      width: 100%;
      clear: both;
   }
   #content{
    display: block;
   }
   .RecommendedList{
    display: none;
   }
   .SideLinks a {
    width: 33.33% !important;
    float: left !important;
    display: block;
    min-width: 0px;
    clear: none;
    font-size: 1.2em !important;
    padding: 20px 11px !important;
    overflow: hidden;
    text-align: center;
    }
    #breadcrumbs{
      display: none;
    }
}
@media all and (max-width: 1110px) {
  .bottomLeftBorder,
  .bottomRightBorder{
    width: 100%;
  }
}
@media all and (max-width: 1842px) {
  .innerHabits {
    padding-left: 0px;
    width: 1013px;
    min-width: auto;
    margin: 0 auto;
    float: none;
    padding-right: 0px;
    max-width: calc(100% - 590px);
}

  .midTac{
    text-align: center;
  }
}
@media all and (max-width: 1280px) {
  .FooterLeft{
    width: 100%; 
    min-width: 100%; 
    text-align: center;
    img{
      width: 90%;
      max-width: 350px;
    }
  }
  .FooterRight{
    width: 100%; 
    min-width: 100%; 
  }
  footer{
    .inner.flex{
      display: block !important;
    }
  }
  .footerLogo {
    display: inline-block;
    margin-bottom: 10px;
  }
  .fse {
    position: relative;
    bottom: auto;
    width: 100%;
    padding-bottom: 20px;
  }

}
@media all and (max-width: 1035px) {
  *{
    -webkit-background-attachment: initial !important;
    -moz-background-attachment:    initial !important;
    background-attachment:         initial !important;
  }
  .parallax{
    @include transform(translateY(0px) !important);
    top: 0px !important;
    margin-top: 0px !important;
  }
}
@media all and (min-width: 1000px) {
  #sidebar{
    .Level-1{
      display: block !important;
      margin-top: 0% !important;
    }
    ul{
      ul{
        display: none;
      }
    }
    .mainToggle:first-of-type{
      display: none  !important;
    }
  }
  .hideSideBarToggle{
    display: none;
  }
}

/* ------------  General Site Responsive ----------- */
@media all and (min-width: 1201px)  {
	#MobileNavigation{
		display: none !important;
	}
}
@media all and (max-width: 1200px)  {
	#MenuIcon{
		display: block !important;
	}
	#Menu{
		display: none !important;
	}
}

@media all and (max-width: 880px)  {
	.contentPage{
		width: 100%;
		display: block;
		clear: both;
		padding: 0px !important;
	}
}
@media all and (max-width: 786px)  {
	.tacMid{
		text-align: center !important;
	}
	.clearMid{
		width: 100%;
		padding: 0px !important;
		clear: both;
	}
}
/*** Newsletters Page Responsive ***/
@media all and (max-width: 726px)  {
	.NewsletterItem{
		width: 50% !important;
	}
}
@media all and (max-width: 500px)  {
    .NewsletterItem{
		width: 100% !important;
	}
}
/*** Newsletters Page Responsive ***/

/*** Staff Responsive ***/
@media all and (max-width: 520px)  {
	.StaffPage .staffProfile .profilePic {
	    margin: 0 0px 30px 0 !important;
	    float: left;
	    width: 100%;
	    clear: both;
	    display: block;
	}
}
/*** Staff Responsive ***/
/*** Gallery Responsive ***/

@media all and (max-width: 800px)  {
    .galleryImage{
        width: 50% !important;
        padding-bottom: 50% !important;
    }
}
@media all and (max-width: 768px)  {
	.grid-item, .grid-sizer { 
		width: 50% !important; 
	}
}
@media all and (max-width: 500px)  {
    .galleryImage{
        width: 100% !important;
        padding-bottom: 100% !important;
    }
}

@media all and (max-width: 1310px)  {
	.PageBoxOuter{
		width: 50%;
	}
}

@media all and (max-width: 545px)  {
  .QuicklinksContainer{
    display: none !important;
  }
}
 
@media all and (max-width: 1120px)  {
  #Home2 .maskHex{
    display: none !important;
  }
  #Home2 .hexagon1{
    display: none !important;
  }
  #Home2{
    .inner{
      z-index: 990;
    }
  }

}

@media all and (max-width: 1180px)  {
  .RightNews,
  .LeftTweets{
    width: 100%;
    display: block;
    padding: 20px 40px !important;
  }
  #Home5 .TweetsTitle {
      position: relative;
      left: auto;
      width: 100%;
      text-align: center;
      padding-left: 0px;
  }
  .NewsTitleOne,
  .NewsTitleTwo{
      text-align: center;
  }
  #Home5 .LeftTweets{
    padding-left: 40px;
    .readMore{
      display: inline-block;
      width: auto;
    }
  }

}
@media all and (max-width: 830px)  {
  .EventSection{
    width: 100%;
    clear: both;
  }
}
@media all and (max-width: 763px)  {
  .habbit {
    margin: 20px 0px;
  }
}
@media all and (max-width: 1230px)  {
  .bgImg{
    display : none;
  }

  .HousePointsImage{
    display: none;
  }

  #Home7{
    *{
      text-align: center;
    }
  }

  .Home6TitleTwo,
  .Home6TitleOne{
      text-align: center;
  }

  .habitsBottom{
    .col-10{
      padding-bottom: 20px;
    }
    .col-10,
    .col-2{
      width: 100%;
      text-align: center;
    }
  }

  .innerHabits {
    padding: 0px 40px;
    width: 100%;
    float: left;
    max-width: 100%;
  }

  .EventTitle {
    font-size: 1.6em;
  }
  #Home3{
    .col-10{
      width: 100%;
      display: block;
    }
    .col-2{
      width: 100%;
      display: block;
      text-align: center;
      margin-bottom: 20px;
    }
  }
}
@media all and (max-width: 800px)  {
  .WelcomeLeft,
  .WelcomeRight{
    width: 100%;
    float: left;
    clear: both;
    text-align: center;
  }
  .WelcomeRight{
    padding-top: 20px !important;
  }
  .WelcomeTitleTwo,
  .WelcomeTitleOne{
    font-size: 1.8em !important;
  }
  .HexContainer {
    bottom: -198px;
    margin-left: -76px;
    transform: scale(0.7);
}
}
@media all and (max-width: 1591px)  {
  .HexContainer {
    bottom: -198px;
  }
}

@media all and (max-width: 996px)  {
  .bannerLinks{
    display: none !important;
  }
}
@media all and (max-width: 650px)  {
  .bannerText {
    font-size: 1.8em;
    padding: 0px 40px;
  }
  .SmallBoxRightInnerButtons a{
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media all and (max-width: 780px)  {
  footer{
    .flex{
      display: block !important;
      float: left;
      width: 100%;
    }
    *{
      text-align: center !important;
    }
    .col-3,
    .col-6{
      display: block;
      width: 100%;
      clear: both;
    }
    .logoFooter {
        position: relative !important;
        bottom: auto !important;
        width: 100% !important;
        width: 150px !important;
        display: inline-block;
        right: auto !important;
        margin-top: 20px;
    }
  }
}

@media all and (max-width: 700px)  {
	.SideLinks a{
		width: 100% !important;
	} 
}
@media all and (max-width: 700px)  {
	.PageBoxOuter{
		width: 100%;
	}
	.PageBox .BoxOverlayTextTitle {
    	font-size: 1.3em;
    }
}
@media all and (max-width: 1070px)  {
  #Home4 .col-4{
    width: 50% !important;
  }  
  #Home4 .col-4:last-of-type{
   display: none;
  }
}

@media all and (max-width: 800px)  {
  #Home4 .col-4{
    width: 100% !important;
  } 
  .LargeBoxTitle,
  .SmallBoxLeftTitle{
    font-size: 1.3em;
  }
}
@media all and (max-width: 580px)  {
  .bannerContact{
    display: none !important;
  }
  .InnerPageTitleThree {
      font-size: 1.3em;
  }
  .InnerPageImage .CenterText {
    font-size: 2em;
  }

  .TweetSlideInnerContent{
    padding-left: 0px !important;
  }
  
  #Home5 .maskHex{
    display: none;
    .controls {
        width: 100%;
        text-align: center;
    }
  }

.homebtn img {
    width: 56%;
  }

.InnerPageTitleOne{
  text-transform: uppercase;
  font-size: 1.8em;
  font-family: "tenez";
}
  .InnerPageTitleTwo{
  font-size: 1.8em;
  }
}
@media all and (max-width: 500px)  {
footer {
    padding: 40px 20px 60px 20px;
    }
  .EventSectionInner{
    .col-9,
    .col-3{
      width: 100%;
      text-align: center;
    }
  }

  .SmallBox{
    font-sizE: 0.8em;
  }
  .SmallBoxDate{
    display: none;
  }
  .newsControls {
  text-align: center;
  }
  .newsControls .controls {
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    right: auto;
    left: 50%;
    bottom: -70px;
    top: auto;
}

}
@media all and (max-width: 450px)  {
	.grid-item, .grid-sizer { 
		width: 100% !important; 
	}
}

@media all and (max-width: 700px)  {
	.SideLinks a{
		width: 100% !important;
	} 
}
/*** Gallery Responsive ***/

/* ------------  General Site Responsive ----------- */
